<template>
  <div class="my-courses">
    <v-tabs v-model="tab" centered background-color="transparent" class="my-6">
      <v-tab>DEVAM EDENLER</v-tab>
      <v-tab>TAMAMLANANLAR</v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-container>
            <v-row v-if="activeCourses.length > 0">
              <v-col cols="12" md="10" offset-md="1">
                <v-row>
                  <v-col
                    v-for="(item, i) in activeCourses"
                    :key="i"
                    md="4"
                    class="mb-4"
                  >
                    <course-card
                      :previewImg="item.previewImg"
                      :code="item.code"
                      :color="item.color"
                      :name="item.name"
                      :progress="item.progress"
                      :urlCode="item.urlCode"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-center my-12">
                    <v-btn
                      rounded
                      large
                      elevation="0"
                      :to="{ name: 'course-catalog' }"
                      color="primary"
                      >Diğer Eğitimlere Gözat</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" md="6" offset-md="3" class="my-12">
                <div class="msg">
                  <v-icon size="60" class="mb-4" color="secondary">info</v-icon>
                  <p>Devam eden eğitiminiz bulunmamaktadır.</p>
                  <p class="pt-6">
                    <v-btn
                      rounded
                      large
                      :to="{ name: 'course-catalog' }"
                      color="primary"
                      elevation="0"
                      >Eğitimlere Gözat</v-btn
                    >
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container>
            <v-row v-if="passiveCourses.length > 0">
              <v-col cols="12" md="10" offset-md="1">
                <v-row>
                  <v-col
                    v-for="(item, i) in passiveCourses"
                    :key="i"
                    md="4"
                    class="mb-4"
                  >
                    <course-card
                      :previewImg="item.previewImg"
                      :code="item.code"
                      :color="item.color"
                      :name="item.name"
                      :progress="item.progress"
                      :urlCode="item.urlCode"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" md="6" offset-md="3" class="my-12">
                <div class="msg">
                  <v-icon size="60" class="mb-4" color="secondary">info</v-icon>
                  <p>Tamamlanmış eğitiminiz bulunmamaktadır.</p>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
import CourseCard from "./CourseCard.vue";

export default {
  metaInfo: () => ({
    title: "Eğitimlerim"
  }),
  components: {
    CourseCard
  },
  data: () => ({
    tab: null,
    passiveCourses: [],
    activeCourses: []
  }),
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    }
  },
  methods: {
    async getRegisteredCourses() {
      try {
        // Get registered courses of current user
        const qsCregs = await firebase
          .firestore()
          .collection("courseRegistrations")
          .where("uid", "==", this.user.id)
          .get();

        qsCregs.forEach(async docCreg => {
          const creg = docCreg.data();
          creg.id = docCreg.id;

          // Get course infos
          const qsCourse = await firebase
            .firestore()
            .collection("courses")
            .doc(creg.courseId)
            .get();

          creg.code = qsCourse.data().code;
          creg.urlCode = qsCourse.data().urlCode;
          creg.name = qsCourse.data().name;
          creg.previewImg = `/courses/${
            qsCourse.data().urlCode
          }/preview-image.jpg`;

          if (creg.progress < 100) {
            this.activeCourses.push(creg);
          } else {
            this.passiveCourses.push(creg);
          }
        });
      } catch (error) {
        throw `Kayıtlı olunan eğitimler alınamadı: ${error}`;
      }
    }
  },
  mounted() {
    this.getRegisteredCourses();
  }
};
</script>

<style lang="scss" scoped>
.my-courses {
  .v-tabs-items {
    background-color: transparent;
  }

  .msg {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
    text-align: center;
    background-color: #fff;
    padding: 30px;
    margin: 30px;
  }
}
</style>
